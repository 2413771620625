import { toast } from 'react-toastify'
import logOut from '../functions/auth'
import getLocale from '../functions/getLocale'

// handle when there's any error in the request
const sessionErrs = [
  'Signature verification raised',
  'Signature has expired',
  'Not enough or too many segments',
  'revoked token',
]
export const onRequestFaild = error => {
  toast.error(error)
  if (sessionErrs.includes(error)) {
    logOut()
  }
}

const config = {
  host: process.env.REACT_APP_API,
  headers: {
    Accept: 'application/json',
    'content-type': 'application/json',
    'cache-control': 'reload',
    Authorization: `Bearer  ${localStorage.getItem('token')}`,
    locale: getLocale(),
  },
  data_error: 'error',
  onFaild: onRequestFaild,
  endpoints: [
    {
      url: 'auth/login',
      method: 'post',
      id: 'auth',
      action: 'USER_LOGIN',
      headers: {
        accept: 'application/json',
        locale: getLocale(),
      },
    },
    {
      url: 'categories',
      method: 'get',
      id: 'categories',
      action: 'GET_CATEGORIES',
    },
    {
      url: 'categories/:id',
      method: 'get',
      id: 'category',
      action: 'GET_CATEGORY',
    },
    {
      url: 'categories',
      method: 'post',
      id: 'category',
      action: 'CREATE_CATEGORY',
    },
    {
      url: 'categories/:id',
      method: 'put',
      id: 'category',
      action: 'UPDATE_CATEGORY',
    },
    {
      url: 'categories/:id',
      method: 'delete',
      id: 'category',
      action: 'DELETE_CATEGORY',
    },
    {
      url: 'owners',
      method: 'get',
      id: 'owners',
      action: 'GET_OWNERS',
    },
    {
      url: 'owners/:id',
      method: 'get',
      id: 'owner',
      action: 'GET_OWNER',
    },
    {
      url: 'owners',
      method: 'post',
      id: 'owner',
      action: 'CREATE_OWNER',
    },
    {
      url: 'owners/:id',
      method: 'put',
      id: 'owner',
      action: 'UPDATE_OWNER',
    },
    {
      url: 'owners/:id',
      method: 'delete',
      id: 'owner',
      action: 'DELETE_OWNER',
    },
    {
      url: 'owners/options',
      method: 'options',
      id: 'owners_options',
      action: 'GET_OWNERS_OPTIONS',
    },
    {
      url: 'profiles',
      method: 'get',
      id: 'profile',
      action: 'GET_PROFILE',
    },
    {
      url: 'profiles',
      method: 'put',
      id: 'profile',
      action: 'UPDATE_PROFILE',
    },
    {
      url: 'trailers/:id/trailer_subscriptions',
      method: 'get',
      id: 'trailer_subscriptions',
      action: 'GET_TRAILER_SUBSCRIPTIONS',
    },
    {
      url: 'trailers',
      method: 'get',
      id: 'trailers',
      action: 'GET_TRUCKS',
      removeIndex: true,
    },
    {
      url: 'trailers',
      method: 'post',
      id: 'trailer',
      action: 'CREATE_TRUCK',
    },
    {
      url: 'trailers/:id',
      method: 'get',
      id: 'trailer',
      action: 'GET_TRUCK',
    },
    {
      url: 'trailers/:id',
      method: 'put',
      id: 'trailer',
      action: 'UPDATE_TRUCK',
    },
    {
      url: 'trailers/delete_image',
      method: 'delete',
      id: 'trailer_images',
      action: 'DELETE_TRUCK_IMAGE',
    },
    {
      url: 'owner/trailers',
      method: 'get',
      id: 'trailers',
      action: 'GET_OWNER_TRUCKS',
      removeIndex: true,
    },
    {
      url: 'owner/trailers/:id',
      method: 'get',
      id: 'trailer',
      action: 'GET_OWNER_TRUCK',
    },
    {
      url: 'owner/trailers/:id',
      method: 'put',
      id: 'trailer',
      action: 'UPDATE_OWNER_TRUCK',
    },
    {
      url: 'owner/trailers/delete_image',
      method: 'delete',
      id: 'trailer_images',
      action: 'DELETE_OWNER_TRUCK_IMAGE',
    },
    {
      url: 'owner/trailers/:id/update_images',
      method: 'put',
      id: 'trailer_images',
      action: 'ADD_OWNER_TRUCK_IMAGES',
    },
    {
      url: 'owner/trailers',
      method: 'post',
      id: 'owner_trailer',
      action: 'CREATE_OWNER_TRUCK',
    },
    {
      url: 'owner/trailers/:id',
      method: 'put',
      id: 'owner_trailer',
      action: 'UPDATE_OWNER_TRUCK',
    },
    {
      url: 'owner/trailers/delete_image',
      method: 'delete',
      id: 'owner_trailer_images',
      action: 'DELETE_OWNER_TRUCK_IMAGE',
    },
    {
      url: 'users',
      method: 'get',
      id: 'users',
      action: 'GET_USERS',
    },
    {
      url: 'users/:id',
      method: 'get',
      id: 'user',
      action: 'GET_USER',
    },
    {
      url: 'users/:id',
      method: 'delete',
      id: 'user',
      action: 'DELETE_USER',
    },
    {
      url: 'auth/confirm',
      method: 'get',
      id: 'email_confirmation',
      action: 'CONFIRM_EMAIL',
    },
    {
      url: 'auth/password',
      method: 'post',
      id: 'forgot_password',
      action: 'FORGOT_PASSWORD',
    },
    {
      url: 'auth/password',
      method: 'put',
      id: 'reset_password',
      action: 'RESET_PASSWORD',
    },
    {
      url: 'bunners',
      method: 'get',
      id: 'banners',
      action: 'GET_BANNERS',
    },
    {
      url: 'bunners/:id',
      method: 'get',
      id: 'banner',
      action: 'GET_BANNER',
    },
    {
      url: 'bunners',
      method: 'post',
      id: 'banner',
      action: 'CREATE_BANNER',
    },
    {
      url: 'bunners/:id',
      method: 'put',
      id: 'banner',
      action: 'UPDATE_BANNER',
    },
    {
      url: 'bunners/:id',
      method: 'delete',
      id: 'banner',
      action: 'DELETE_BANNER',
    },
    {
      url: 'auth/register',
      method: 'post',
      id: 'register',
      action: 'OWNER_REGISTER',
    },
    {
      url: 'notifications',
      method: 'get',
      id: 'notifications',
      action: 'GET_NOTIFICATIONS',
    },
    {
      url: 'notifications',
      method: 'post',
      id: 'notification',
      action: 'CREATE_NOTIFICATION',
    },
    {
      url: 'requests',
      method: 'get',
      id: 'change_requests',
      action: 'GET_REQUESTS',
    },
    {
      url: 'requests/:id',
      method: 'get',
      id: 'change_request',
      action: 'GET_REQUEST',
    },
    {
      url: 'requests/:id',
      method: 'put',
      id: 'change_request',
      action: 'UPDATE_REQUEST',
    },
    {
      url: 'owner/requests',
      method: 'get',
      id: 'change_requests',
      action: 'GET_OWNER_REQUESTS',
    },
    {
      url: 'owner/requests/:id',
      method: 'get',
      id: 'change_request',
      action: 'GET_OWNER_REQUEST',
    },
    {
      url: 'owner/requests/:id',
      method: 'put',
      id: 'change_request',
      action: 'UPDATE_OWNER_REQUEST',
    },
    {
      url: 'owner/requests/:id',
      method: 'delete',
      id: 'change_request',
      action: 'CANCEL_OWNER_REQUEST',
    },
    {
      url: 'dashbords',
      method: 'get',
      id: 'dashboards',
      action: 'GET_DASHBOARDS',
    },
    {
      url: 'countries',
      method: 'get',
      id: 'countries',
      action: 'GET_COUNTRIES',
    },
    {
      url: 'countries',
      method: 'post',
      id: 'country',
      action: 'CREATE_COUNTRY',
    },
    {
      url: 'countries/:id',
      method: 'get',
      id: 'country',
      action: 'GET_COUNTRY',
    },
    {
      url: 'countries/:id',
      method: 'put',
      id: 'country',
      action: 'UPDATE_COUNTRY',
    },
    {
      url: 'subscriptions',
      method: 'get',
      id: 'subscriptions',
      action: 'GET_SUBSCRIPTIONS',
    },
    {
      url: 'subscriptions/:id',
      method: 'get',
      id: 'subscription',
      action: 'GET_SUBSCRIPTION',
    },
    {
      url: 'subscriptions/:id',
      method: 'put',
      id: 'subscription',
      action: 'UPDATE_SUBSCRIPTION',
    },
    {
      url: 'subscriptions/:id',
      method: 'delete',
      id: 'subscription',
      action: 'CANCEL_SUBSCRIPTION',
    },
  ],
}

export default config
