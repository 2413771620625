const getLocale = () => {
  const lng = localStorage.getItem('i18nextLng')
  if (lng) {
    if (lng.toString() === 'en-US') return 'en'
    return lng
  }
  return 'en'
}

export default getLocale
