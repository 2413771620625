import React from 'react'
import { Route, Switch } from 'react-router-dom'
import RedirectRoute from './RedirectRoute'

// Containers
const Login = React.lazy(() => import('components/Pages/Login/index'))
const EmailConfirmation = React.lazy(() => import('components/Pages/EmailConfirmation'))
const ForgotPassword = React.lazy(() => import('components/Pages/ForgotPassword'))
const ResetPassword = React.lazy(() => import('components/Pages/ResetPassword'))
const OwnerRegistration = React.lazy(() => import('components/Pages/OwnerRegistration'))
// const WelcomePage = React.lazy(() => import('components/Pages/WelcomePage'))
const SupscripeSelecting = React.lazy(() => import('components/Pages/SubscriptionSelecting'))
const DefaultLayout = React.lazy(() => import('components/UI/DefaultLayout'))

const isLogged = () => {
  const token = localStorage.getItem('token')
  return !!token
}

const RootRouter = () => (
  <Switch>
    <Route exact path="/confirm_email/:token" name="Confirm Email" component={EmailConfirmation} />
    <Route exact path="/forgot_password" name="Forgot Password" component={ForgotPassword} />
    {/* <Route exact path="/welcome" name="Welcome" component={WelcomePage} /> */}
    <Route
      exact
      path="/supscripe_selecting"
      name="Supscripe Selecting"
      component={SupscripeSelecting}
    />
    <Route exact path="/reset_password/:token" name="Reset Password" component={ResetPassword} />
    <Route
      exact
      path="/owner_registration/:subscription_type"
      name="Owner Registration"
      component={OwnerRegistration}
    />
    <RedirectRoute path="/login" component={Login} condition={!isLogged()} redirect="/" />
    <RedirectRoute path="/" component={DefaultLayout} condition={isLogged()} redirect="/login" />
  </Switch>
)
export default RootRouter
