import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import RootRouter from './routers/RootRouter'
import './App.scss'
import 'react-toastify/dist/ReactToastify.min.css' // minfied version of css file (.min.css)
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// Create a client
const queryClient = new QueryClient()

const App = () => (
  <React.Fragment>
    <QueryClientProvider client={queryClient}>
      <Router>
        <RootRouter />
      </Router>
      <ToastContainer />
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.Fragment>
)

export default App
